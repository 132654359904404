/* General styling for the SVG paths */
.svgPath {
    stroke-width: 20;
    fill: none;
    stroke-dasharray: 3000;
    stroke-dashoffset: 3000;
    animation: draw 2s ease-in-out infinite alternate, fillColor 2s ease-in-out infinite alternate;
}

/* Top path styling */
.topPath {
    stroke: #ffbf00;
    animation: draw 2s ease-in-out infinite alternate, fillColorTop 2s ease-in-out infinite alternate;
}

/* Bottom path styling */
.bottomPath {
    stroke: #a637a5;
    animation: draw 2s ease-in-out infinite alternate, fillColorBottom 2s ease-in-out infinite alternate;
}

/* Keyframes for drawing the stroke */
@keyframes draw {
    0% {
        stroke-dashoffset: 3000;
        fill: none;
    }
    50% {
        stroke-dashoffset: 0;
        fill: none;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

/* Keyframes for filling the top path */
@keyframes fillColorTop {
    0%,
    50% {
        fill: transparent;
    }
    75% {
        fill: #ffbf00;
    }
    100% {
        fill: #fbc72c;
    }
}

/* Keyframes for filling the bottom path */
@keyframes fillColorBottom {
    0%,
    50% {
        fill: transparent;
    }
    75% {
        fill: #a637a5;
    }
    100% {
        fill: rgb(198, 84, 196);
    }
}
